.login-body {
	background: #f8f9fa;
	color: #495057;
    font-family: "Nunito";
	.login-wrapper {
		display: -ms-flexbox;
		display: flex;
		height: 100vh;
		.login-panel {
			width: 80%;
			height: 85%;
			text-align: center;
			padding: 40px 20px;
			-ms-flex-align: center;
			align-items: center;
			-ms-flex-pack: justify;
			-ms-flex-direction: column;
			flex-direction: column;
			.logo {
				height: 50px;
			}
			.login-form {
				>h1 {
					border: dotted 3px #ffffff ;
					background-color: #bec4ca;
					padding: 20px;
					border-radius: 10px; 
				}
				display: -ms-flexbox;
				display: flex;
				-ms-flex-align: center;
				align-items: center;
				-ms-flex-direction: column;
				flex-direction: column;
				>p {
					font-weight: 600;
					margin: 0;
					color: #6c757d;
					margin-bottom: 32px;
					>a {
						color: #1976D2;
						cursor: pointer;
					}
				}
				>input {
					width: 85%;
					max-width: 310px;
					margin-bottom: 20px;
				}
				>button {
					width: 85%;
					max-width: 310px;
				}
			}
			p {
				font-weight: 600;
				margin: 0;
				color: #6c757d;
				>a {
					color: #1976D2;
				}
			}
		}
		.login-image {
			width: 70%;
			height: 100%;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			-ms-flex-pack: justify;
			-ms-flex-direction: column;
			flex-direction: column;
			padding: 40px 20px;  
		}
	}
}
@media screen and (max-width: 576px) {
	.layout-wrapper {
		.layout-topbar {
			.topbar-menu {
				>li {
					position: static;
					>ul {
						position: fixed;
						top: 7.125rem;
						left: 0;
						width: 100%;
						right: auto;
					}
				}
			}
		}
	}
}
@media (min-width: 993px) {
	.login-body {
		.login-wrapper {
			.login-image {
				background-size: cover;
				background-repeat: no-repeat; 
				background-position: center center;
			}
		}
	}
}

@media (max-width: 992px) {
	.login-body {
		.login-wrapper {
			.login-panel {
				width: 100%;
				z-index: 2;
			}
			.login-image {
				display: none;
			}
		}
	}
}
.justify-content-between
{
    justify-content: space-between!important;
}



.layout-main {
    margin-left: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
	font-family: "Nunito";
    .layout-content {
        padding-top: 70px;
        flex: 1 1 0;
		
        > div:not(.home-page) {
            padding: 28px;
        }
    }
}

@media screen and (max-width: 960px) {
    .layout-main {
        margin-left: 0;
		font-family: "Nunito";
        .layout-content {
            padding-top: 110px;
        }
    }

    .layout-mask {
        &.layout-mask-active {
            z-index: 998;
            width: 100%;
            height: 100%;
            position: fixed;
            top: 70px;
            left: 0;
            background-color: rgba(0,0,0,.4);
            transition: background-color .5s;
        }
    }
} 