/* You can add global styles to this file, and also import other style files */

// Add style for modal dropdown
.filter-dropdown {
  position: relative;

  .dropdown-container {
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0px 10px 19px 0px #0000000d;
    min-width: 420px;
    padding: 15px 30px 15px 15px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    top: calc(100% + 5px);
    right: 0;

    .dropdown-content {
      margin-bottom: 15px;

      .checkbox label {
        font-size: 15px;
      }
      label {
        font-size: 12px;
        color: #6c757d;
        margin: 2px 0;
      }
    }
  }

  .dropdown-button {
    border: 1px solid #e5eaf0;
    background: none;
    height: 44px;
    border-radius: 40px;
    padding: 14px 20px 14px 20px;
    color: #212529;
    font-size: 15px;
    font-weight: 500;
    font-family: Urbanist Regular;
    align-items: center;
    display: flex;
    cursor: pointer;

    .dropdown-image {
      margin-right: 8px;
    }
  }

  .dropdown-button:hover {
    background-color: rgba(209, 208, 249, 0.3);
    border: 1px solid transparent;
  }

  .custom-button {
    width: 100%;
    height: 50px;
    max-width: 88px;
    background-color: #4942e5;
    border: 1px solid #4942e5;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: end;

    button {
      cursor: pointer;
    }
  }

  .buttons-wrapper button:first-child {
    border: none;
    background-color: transparent;
    margin: 0 20px;
    font-size: 15px;
    font-family: Urbanist Regular;
  }
}

.filter-dropdown .dropdown-content input[type='text'],
.filter-dropdown .dropdown-content input[type='checkbox'] {
  font-family: Urbanist Regular;
}

.filter-dropdown .dropdown-content button {
  margin-right: 10px;
}

.filter-dropdown.isOpen .dropdown-button {
  background: #d1d0f9;
  border: 1px solid #d1d0f9;
}

.keyword {
  display: inline-block;
  background-color: #f0f2f4;
  padding: 6px 13px;
  border-radius: 25px;
  margin-right: 5px;
  margin-bottom: 5px;
  border: none;
  font-size: 15px;
  font-family: Urbanist Regular;
  color: #495057;
}

.header-row {
  font-size: 12px;
}

.login-container, .verification-container {
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('./assets/images/backgroundImage.png');
  background-repeat: no-repeat;

  .p-toast.p-toast-top-right,
  .p-toast.p-toast-top-left,
  .p-toast.p-toast-top-center {
    top: 27%;
    min-width: 339px;
    padding: 10px;
    background: transparent;
  }

  .p-toast {
    position: absolute;
    right: 19%;
  }

  .login-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 3rem;
    position: relative;

    img {
      margin-top: 5rem;
    }

    .modal-wrapper {
      width: 553px;
      height: 599px;
      border-radius: 40px;
      background-color: rgba(209, 208, 249, 0.5);
      margin-bottom: 7rem;

      div {
        width: 542px;
        height: 583px;
        border-radius: 40px;
        background-color: rgba(255, 255, 255, 1);
        padding: 6rem;
        // display: flex;
        // align-items: center;
        text-align: center;
        // justify-content: center;

        h3 {
          font-size: 21px;
          font-weight: 700;
          margin: 0;
        }

        .info {
          font-size: 15px;
          font-weight: 500;
        }
        form {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 2rem 0;

          label,
          p {
            width: 100%;
            text-align: left;
            max-width: 339px;
            font-size: 12px;
            font-weight: 600;
            color: #6c757d;
          }
          p > a {
            font-size: 12px;
          }

          input:nth-child(4) {
            margin-bottom: 0;
          }

          input {
            width: 100%;
            max-width: 339px;
            background: white;
            height: 45px;
            gap: 0px;
            border-radius: 6px;
            padding: 1rem;
            border: 1px solid #4942e5;
            background-clip: text;
            margin-bottom: 1rem;
            margin-top: 0.2rem;
          }

          input:focus {
            box-shadow: none;
          }
        }
        .login-button {
          background: #4942e5;
          color: white;
          font-weight: 700;
          width: 100%;
          max-width: 339px;
          height: 45px;
          border: 1px solid #4942e5;
          border-radius: 8px;
          padding: 17px 20px 17px 20px;
          margin: 1rem 0;
        }

        .login-button:focus {
          box-shadow: none;
        }

        p {
          font-weight: 700;
          font-size: 15px;
          a {
            color: #4942e5;
          }
        }
      }
    }
  }
}

.p-button:focus {
  outline: none;
  box-shadow: none;
}

#singleSelectInput {
  height: 44px;
  display: flex;
  align-items: center;
  width: 100%;

  .p-element.p-dropdown-label.p-inputtext.p-placeholder.ng-star-inserted {
    font-size: 15px;
    font-family: 'Urbanist Regular';
    padding: 0;
  }

  .p-dropdown.p-component.p-inputwrapper {
    padding: 10px;
  }

  .p-ripple.p-element.p-dropdown-item {
    height: 40px;
    padding: 10px;
  }

  .p-ripple.p-element.p-dropdown-item:hover {
    background-color: #d1d0f9;
  }

  .p-dropdown-panel.p-dropdown-items.p-dropdown-item.p-highlight.p-focus {
    background: #dee2e6;
    color: black;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: black;
    background: #d1d0f9;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
    border-color: #ced4da;
  }
}

.user-info {
  display: flex;
  justify-content: space-between;

  h1 {
    margin-bottom: 0;
  }

  .profile-title {
    max-width: 850px;
  }

  .profile-photo {
    width: 78px;
    height: 78px;
    border-radius: 50%;
  }

  .custom-button {
    padding: 0 20px;
    height: 45px;
    background-color: #4942e5;
    border: 1px solid #4942e5;
    color: white;
    border-radius: 8px;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
  }
}
.tag {
  background-color: #d1d0f9;
  padding: 6px 13px;
  border-radius: 25px;
  margin: 0 0 0 5px;
  color: #4d4d4b;
  display: flex;
  align-items: center;
  width: fit-content;
}

input[type='checkbox'] {
  display: none;
}

.custom-checkbox-label {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #dadada;
  border-radius: 4px;
}

.custom-checkbox-label input[type='checkbox']:checked + .custom-checkbox-icon {
  background-image: url('../src/assets/images/check.svg');
  background-size: cover;

  display: block;
}

.custom-checkbox-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.table-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-height: 100%;
  max-width: 193px;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
}

.ellipsis-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
}

.wrapper {
  height: 100%;
  position: relative;
}

.wrapper:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.status {
  height: 23px !important;
  width: max-content;
  padding: 5px 13px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  &.succeed {
    background-color: #e1f5e9;
    color: #6fcf97 !important;
  }

  &.pending {
    background-color: #f4f4f4;
    color: #6c757d !important;
  }

  &.in-progress {
    background-color: #e2f6fd;
    color: #56ccf2 !important;
  }

  &.created {
    background-color: #fdf5de;
    color: #f2c94c !important;
  }

  &.active {
    background-color: #D1D0F9;
    color: #4942E5 !important;
  }

  &.failed {
    background-color:#FEDCDC;
    color: #EB5757 !important;
  }
}

.modal-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .modal-title {
    font-size: 21px;
    font-weight: 700;
    color: #21201E;
    margin: 1rem 0 0.3rem 0;
  }

  .modal-description{
    font-size: 15;
    font-weight: 500;
    color: #6C757D;
    margin-bottom: 2rem;
  }
}

.general-button-purple {
  background-color: #4942e5;
  border: none;
  color: white;
  height: 44px;
  border-radius: 8px;
  width: -webkit-fill-available;
  max-width: 339px;
  padding: 15px;
  font-size: 15px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}